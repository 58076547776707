<template>
	<div class="mtipocanalcmncFormHeader puiformheader">
		<v-row>
			<v-col cols="12">
				<pui-field :label="$t('header.mtipocanalcmnc.idcanal')" :value="getIdcanalValue"></pui-field>
			</v-col>
		</v-row>
		<v-row>
			<v-col cols="12">
				<pui-field :label="$t('header.mtipocanalcmnc.codigocanal')" :value="getCodigocanalValue"></pui-field>
			</v-col>
		</v-row>
		<v-row>
			<v-col cols="12">
				<pui-field :label="$t('header.mtipocanalcmnc.name')" :value="getNameValue"></pui-field>
			</v-col>
		</v-row>
	</div>
</template>

<script>
import PuiFormHeaderMixin from 'pui9-mixins/PuiFormHeaderMixin';

export default {
	name: 'mtipocanalcmnc-form-header',
	mixins: [PuiFormHeaderMixin],
	data() {
		return {
			modelName: 'mtipocanalcmnc'
		};
	},
	computed: {
		getIdcanalValue() {
			return this.model && this.model.idcanal ? this.model.idcanal : '-';
		},
		getCodigocanalValue() {
			return this.model && this.model.codigocanal ? this.model.codigocanal : '-';
		},
		getNameValue() {
			return this.model && this.model.name ? this.model.name : '-';
		},
	}
}
</script>
